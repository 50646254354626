import React from "react";
import { ProSidebar, SidebarContent, SidebarHeader } from "react-pro-sidebar";
import { MdOutlineDashboard, MdOutlineEventNote } from "react-icons/md";
import { AiOutlineSetting } from "react-icons/ai";
import { TbClipboardList } from "react-icons/tb";
import { FiLogOut } from "react-icons/fi";
import { CgNotes } from "react-icons/cg";
import { IoMdMedkit } from "react-icons/io";

import AMenu from "../atoms/AMenu";
import { logo } from "../../assets/images";

import "react-pro-sidebar/dist/css/styles.css";
import "../../styles/sidebar.css";

const listPath = [
  {
    title: "Dashboard",
    path: "dashboard",
    icon: MdOutlineDashboard,
    hideOnDesktop: false,
  },
  {
    title: "Produk",
    path: "product",
    icon: CgNotes,
    hideOnDesktop: false,
    hasChildren: true,
    children: [
      {
        title: "Tambah Produk",
        path: "/product/add-product",
        icon: IoMdMedkit,
      },
      { title: "Daftar Produk", path: "/product", icon: TbClipboardList },
    ],
  },
  {
    title: "Pesanan",
    path: "order",
    icon: MdOutlineEventNote,
    hideOnDesktop: false,
  },
  {
    title: "Pengaturan",
    path: "settings",
    icon: AiOutlineSetting,
    hideOnDesktop: false,
  },
  {
    title: "Logout",
    path: "login",
    icon: FiLogOut,
    hideOnDesktop: true,
  },
];

const MSidebar = () => {
  // const { GlobalState, GlobalDispatch } = useContext(GlobalContext);
  // const { openSidebar } = GlobalState;

  // const handleCollapseSidebar = () => {
  //   GlobalDispatch({
  //     type: ActionType.SetOpenSidebar,
  //     payload: !openSidebar,
  //   });
  // };

  const handleLogout = () => {
    console.log("logout will be trigger here");
  };

  return (
    <aside className="z-20 h-full">
      <ProSidebar width={235}>
        <SidebarHeader className="h-20 flex justify-center items-center w-full">
          <div className="w-3/4">
            <img src={logo} alt="logo" className="w-full" />
          </div>
        </SidebarHeader>
        <SidebarContent className="block mt-12">
          {listPath.map((item, idx) => (
            <AMenu key={idx} item={item} handleLogout={handleLogout} />
          ))}
        </SidebarContent>
      </ProSidebar>
    </aside>
  );
};

export default MSidebar;
