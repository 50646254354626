import { adminAxios } from './axios';

export const getProducts = ({ isActive, keyword, page }) =>
  adminAxios.get('products', {
    params: {
      page,
      include: ['productImages'],
      filter: { isActive },
      keyword,
    },
  });

export const getProduct = (id) =>
  adminAxios.get(`products/${id}`, {
    params: {
      include: [
        'productImages',
        'productVariantTypes.productVariantOptions',
        'productVariants',
        'category.parentCategory',
      ],
    },
  });

export const patchProduct = (id, { isActive }) =>
  adminAxios.patch(`products/${id}`, { isActive });

export const deleteProduct = (id) => adminAxios.delete(`products/${id}`);

export const storeProductImage = (data) =>
  adminAxios.post('products/image', data, {
    headers: { 'Content-Type': 'multipart/form-data' },
  });

export const storeNoVariantProduct = (data) =>
  adminAxios.post('products/no-variants', {
    ...data,
    isInsured: false,
    isActive: true,
  });

export const storeIncludeVariantProduct = (data) =>
  adminAxios.post('products/variants', {
    ...data,
    isInsured: false,
    isActive: true,
  });

export const updateNoVariantProduct = (id, data) =>
  adminAxios.put(`products/no-variants/${id}`, {
    ...data,
    isInsured: false,
    isActive: true,
  });

export const updateIncludeVariantProduct = (id, data) =>
  adminAxios.put(`products/variants/${id}`, {
    ...data,
    isInsured: false,
    isActive: true,
  });
