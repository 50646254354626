export const formatCurrency = (value) => {
  return value
    .toLocaleString('id-ID', {
      style: 'currency',
      currency: 'IDR',
      maximumFractionDigits: 0,
    })
    .replace(/\s+/g, '');
};

export const subsPhoneNumber = (phone_number) => {
  const sub_phone_number =
    Number(phone_number[0]) === 0
      ? phone_number.substring(1)
      : Number(phone_number.substring(0, 2)) === 62
      ? phone_number.substring(2)
      : phone_number;
  return sub_phone_number;
};

export const ordinalSuffixOf = (i) => {
  var j = i % 10,
    k = i % 100;
  if (j === 1 && k !== 11) {
    return 'st';
  }
  if (j === 2 && k !== 12) {
    return 'nd';
  }
  if (j === 3 && k !== 13) {
    return 'rd';
  }
  return 'th';
};
