import { Box, Grid, Typography, useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { ErrorMessage, Form, Formik } from 'formik';
import { useContext, useState } from 'react';
import { Navigate, useLocation, useNavigate } from 'react-router-dom';
import * as Yup from 'yup';
import { getOtp, verifyOtp } from '../../api/auth';
import { getMe } from '../../api/user';
import logoImage from '../../assets/images/logo.png';
import officeImage from '../../assets/images/office.jpg';
import AButton from '../../components/atoms/AButton';
import { ALoading } from '../../components/atoms/ALoading';
import ATextField from '../../components/atoms/ATextField';
import OVerifOtpDialog from '../../components/organisms/authentication/OVerifOtpDialog';
import AuthContext from '../../contexts/AuthContext';

const LoginSchema = Yup.object({
  phoneNumber: Yup.string()
    .required('Nomor hp tidak boleh kosong.')
    .matches(/^[0-9]+$/, 'Hanya menerima inputan berupa angka.')
    .min(9, 'Minimum mengandung 9 angka.')
    .max(13, 'Maksimum mengandung 13 angka.'),
  countryCode: Yup.string().required('Kode negara tidak boleh kosong.'),
});

const Login = () => {
  const { authData } = useContext(AuthContext);
  const theme = useTheme();
  const navigate = useNavigate();
  const matches = useMediaQuery(theme.breakpoints.down('sm'));
  const [isOtpDialogOpen, setIsOtpDialogOpen] = useState(false);
  const location = useLocation();
  const { setAuthData } = useContext(AuthContext);
  const from = location.state?.from?.pathname || '/dashboard';

  const requestGetOtp = async ({ phoneNumber, countryCode }) => {
    try {
      const { outletId } = (await getOtp({ countryCode, phoneNumber })).data
        .data;
      localStorage.setItem('outletId', outletId);
      setIsOtpDialogOpen(true);
    } catch (error) {
      console.error(error);
    }
  };

  if (!authData && localStorage.getItem('accessToken'))
    return (
      <div className="w-screen h-screen flex items-center justify-center">
        <ALoading />
      </div>
    );

  if (authData) return <Navigate to="/" replace />;

  return (
    <Formik
      onSubmit={requestGetOtp}
      initialValues={{ phoneNumber: '', countryCode: '62' }}
      validationSchema={LoginSchema}
    >
      {({ values, handleChange, isSubmitting }) => (
        <Form>
          <OVerifOtpDialog
            isOpen={isOtpDialogOpen}
            setIsOpen={(isOpen) => {
              if (!isOpen) {
                localStorage.clear();
              }
              setIsOtpDialogOpen(isOpen);
            }}
            onSubmit={async ({ otp }) => {
              try {
                const data = (
                  await verifyOtp({
                    otp,
                    phoneNumber: values.phoneNumber,
                    countryCode: values.countryCode,
                  })
                ).data.data;
                localStorage.setItem('accessToken', data.accessToken);
                const authData = (await getMe()).data.data;
                setAuthData(authData);
                navigate(from, { replace: true });
              } catch (error) {
                console.error(error);
              }
            }}
            onResendClick={() => {
              requestGetOtp({
                phoneNumber: values.phoneNumber,
                countryCode: values.countryCode,
              });
            }}
          />
          <Grid
            container
            sx={{
              height: '100%',
              minHeight: '100vh',
              width: 1,
              backgroundImage: `url(${officeImage})`,
              backgroundPosition: 'right',
              backgroundSize: 'cover',
              boxShadow: (theme) =>
                `inset 0 0 0 100vmax ${theme.palette.primary.main}66`,
            }}
          >
            <Grid
              item
              sx={{
                bgcolor: 'background.default',
                p: 4,
                display: 'flex',
                flexDirection: 'column',
                gap: 2,
              }}
              sm={5}
              xs={12}
            >
              <Box
                sx={{
                  height: '8rem',
                  width: '8rem',
                  borderRadius: 1,
                  objectFit: 'contain',
                }}
                component="img"
                src={logoImage}
              />
              <Typography
                sx={{
                  fontSize: '1.5rem',
                  color: 'primary.main',
                  fontWeight: 'bold',
                  mt: 9,
                }}
              >
                Hello,
              </Typography>
              <Typography
                sx={{
                  fontSize: '1.5rem',
                  color: 'primary.main',
                  fontWeight: 'bold',
                }}
              >
                Welcome Back
              </Typography>
              <div className="flex gap-3 mt-4">
                <div className="w-1/5">
                  <ATextField
                    isDisabled
                    type="text"
                    name="countryCode"
                    label="Kode"
                    placeholder="Kode"
                    value={`+${values.countryCode}`}
                  />
                </div>

                <ATextField
                  type="text"
                  name="phoneNumber"
                  inputMode="numeric"
                  placeholder="Masukkan nomor hp"
                  label="Nomor hp"
                  value={values.phoneNumber}
                  handleChange={handleChange}
                />
              </div>
              <ErrorMessage
                name="countryCode"
                component="p"
                className="text-xs text-red-500 mt-1"
              />
              <ErrorMessage
                name="phoneNumber"
                component="p"
                className="text-xs text-red-500 mt-1"
              />
              <AButton
                title="Login"
                type="submit"
                className="w-full mt-4"
                size="md"
                disabled={isSubmitting}
              />

              <Typography
                align="center"
                sx={{
                  color: '#000',
                  fontSize: '1.2rem',
                  fontWeight: 'bold',
                  left: 0,
                  width: '100%',
                  display: !matches && 'none',
                }}
              >
                &copy; PT Raksasa Indonesia
              </Typography>
            </Grid>
            <Grid
              item
              sm={7}
              xs={12}
              sx={{
                display: matches ? 'none' : 'flex',
                justifyContent: 'center',
                alignItems: 'flex-end',
                p: 6,
              }}
            >
              <Typography
                sx={{
                  color: 'common.white',
                  fontSize: '1.5rem',
                  fontWeight: 'bold',
                }}
              >
                &copy; PT Raksasa Indonesia
              </Typography>
            </Grid>
          </Grid>
        </Form>
      )}
    </Formik>
  );
};

export default Login;
