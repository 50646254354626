import { TextField } from '@mui/material';
import React from 'react';

const ATextField = ({
  placeholder = 'Place holder',
  type = 'text',
  handleChange,
  error = false,
  helperText,
  isDisabled = false,
  value,
  name,
  multiline = false,
  maxRows = 1,
  minRows = 1,
  height = '22px',
  paddingLeft,
  label = '',
  inputMode,
  maxLength,
  ...otherProps
}) => {
  return (
    <TextField
      label={label}
      multiline={multiline}
      maxRows={maxRows}
      inputProps={{
        style: {
          height,
          fontSize: '0.8rem',
          paddingTop: '0.6rem',
          paddingBottom: '0.5rem',
          paddingLeft,
          zIndex: 0,
        },
        inputMode,
        maxLength,
      }}
      name={name}
      disabled={isDisabled}
      sx={{ width: '100%' }}
      value={value}
      onChange={handleChange}
      type={type}
      size="small"
      placeholder={placeholder}
      variant="outlined"
      error={error}
      helperText={error && helperText}
      FormHelperTextProps={{
        style: { marginTop: '0.3rem', margin: 0, fontSize: '0.7rem' },
      }}
      {...otherProps}
    />
  );
};

export default ATextField;
