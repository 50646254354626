import { useLayoutEffect } from "react";
import { useLocation } from "react-router-dom";

const Wrapper = ({ children }) => {
  const { pathname, search } = useLocation();

  useLayoutEffect(() => {
    var myDiv = document.getElementById("scrollEl");
    myDiv.scrollTop = 0;
  }, [pathname, search]);
  return children;
};

export default Wrapper;
