import React from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';

const Settings = React.lazy(() => import('../../pages/settings/Settings'));
const Profile = React.lazy(() => import('../../pages/settings/Profile'));
const Payment = React.lazy(() => import('../../pages/settings/Payment'));
const Expedition = React.lazy(() => import('../../pages/settings/Expedition'));
const Account = React.lazy(() => import('../../pages/settings/Account'));
const PaymentMethod = React.lazy(() =>
  import('../../pages/settings/PaymentMethod')
);

const SettingsRoute = () => {
  return (
    <main className="flex justify-center w-full pb-4 h-full">
      <div className="flex w-full justify-center container">
        <Settings />
        <Routes>
          <Route path="/profile" element={<Profile />} />
          <Route path="/payment" element={<Payment />} />
          <Route path="/expedition" element={<Expedition />} />
          <Route path="/account" element={<Account />} />
          <Route path="/payment-method" element={<PaymentMethod />} />

          <Route
            path="/"
            element={<Navigate replace to="/settings/profile" />}
          />
        </Routes>
      </div>
    </main>
  );
};

export default SettingsRoute;
