import AwesomeDebouncePromise from 'awesome-debounce-promise';
import React from 'react';
import AsyncSelect from 'react-select/async';

const AAsyncSelectSearch = ({
  loadOptions,
  placeholder = 'Cari disini...',
  defaultOptions,
  isDisabled,
  onChange,
  value,
  cacheOptions = true,
}) => {
  return (
    <AsyncSelect
      className="font-normal text-xs mt-1.5"
      placeholder={placeholder}
      defaultOptions={defaultOptions}
      loadOptions={AwesomeDebouncePromise(loadOptions, 1000)}
      cacheOptions={cacheOptions}
      isDisabled={isDisabled}
      onChange={onChange}
      value={value}
    />
  );
};

export default AAsyncSelectSearch;
