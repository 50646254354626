import React, { useState } from 'react';
import { FiTrash2 } from 'react-icons/fi';
import CreatableSelect from 'react-select/creatable';

import { InputAdornment } from '@mui/material';
import { ErrorMessage, Form, Formik, getIn, useFormikContext } from 'formik';
import { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import * as Yup from 'yup';
import { getCategories } from '../../api/categories';
import {
  getProduct,
  storeProductImage,
  updateIncludeVariantProduct,
  updateNoVariantProduct
} from '../../api/products';
import { storeProductVariantOptionImage } from '../../api/productVariantOptions';
import AAsyncSelectSearch from '../../components/atoms/AAsyncSelectSearch';
import ABox from '../../components/atoms/ABox';
import AButton from '../../components/atoms/AButton';
import { ALoading } from '../../components/atoms/ALoading';
import ASwitchButton from '../../components/atoms/ASwitchButton';
import ATextField from '../../components/atoms/ATextField';

const ProductSchema = Yup.object().shape({
  images: Yup.array(
    Yup.object({
      isUploading: Yup.boolean().oneOf([false]).required(),
      url: Yup.string().url().nullable(),
    }).required()
  )
    .length(5)
    .required()
    .test(
      'at-least-one-image-is-uploaded',
      'Mohon cantumkan setidaknya satu gambar produk',
      (images) => images.some((image) => image.url)
    ),
  name: Yup.string().required('Mohon isi nama produk'),
  category: Yup.object().required('Mohon pilih kategori produk').nullable(),
  subCategory: Yup.object().nullable(),
  description: Yup.string().required('Mohon isi deskripsi produk'),
  minimumOrderQuantity: Yup.number()
    .typeError('Minimum pemesanan harus berupa angka')
    .integer('Minimum pemesanan harus berupa angka bulat')
    .min(1, 'Minimum pemesanan harus bernilai minimal 1')
    .required('Mohon isi minimum pemesanan'),
  weight: Yup.number()
    .typeError('Berat produk harus berupa angka')
    .positive('Berat produk harus berupa angka positif')
    .moreThan(0, 'Berat produk harus bernilai lebih dari 0')
    .required('Mohon isi berat produk'),
  height: Yup.number()
    .typeError('Tinggi produk harus berupa angka')
    .positive('Tinggi produk harus berupa angka positif')
    .moreThan(0, 'Tinggi produk harus bernilai lebih dari 0')
    .required('Mohon isi tinggi produk'),
  length: Yup.number()
    .typeError('Panjang produk harus berupa angka')
    .positive('Panjang produk harus berupa angka positif')
    .moreThan(0, 'Panjang produk harus bernilai lebih dari 0')
    .required('Mohon isi panjang produk'),
  width: Yup.number()
    .typeError('Lebar produk harus berupa angka')
    .positive('Lebar produk harus berupa angka positif')
    .moreThan(0, 'Lebar produk harus bernilai lebih dari 0')
    .required('Mohon isi lebar produk'),
  variants: Yup.object({
    types: Yup.array(
      Yup.object({
        name: Yup.string().required('Mohon isi nama varian'),
        options: Yup.array(
          Yup.object({
            name: Yup.string().required(),
            image: Yup.object({
              isUploading: Yup.boolean().oneOf([false]).required(),
              url: Yup.string()
                .required('Mohon cantumkan gambar opsi varian')
                .nullable(),
            }).required(),
          }).required()
        )
          .min(1, 'Mohon isi minimal 1 opsi varian')
          .required(),
      }).required()
    ).required(),
    combinations: Yup.array(
      Yup.object({
        price: Yup.number()
          .typeError('Harga kombinasi harus berupa angka')
          .positive('Harga kombinasi harus berupa angka positif')
          .integer('Harga kombinasi harus berupa angka bulat')
          .min(1, 'Harga kombinasi harus bernilai minimal 1')
          .required('Mohon isi harga kombinasi'),
        sku: Yup.string().required('Mohon isi sku kombinasi'),
        stock: Yup.number()
          .typeError('Stok produk harus berupa angka')
          .positive('Stok produk harus berupa angka positif')
          .integer('Stok produk harus berupa angka bulat')
          .min(1, 'Stok produk harus bernilai minimal 1')
          .required('Mohon isi stok kombinasi'),
        isActive: Yup.boolean().required(),
      }).required()
    ).required(),
  }).required(),
  price: Yup.number()
    .typeError('Harga produk harus berupa angka')
    .positive('Harga produk harus berupa angka positif')
    .integer('Harga produk harus berupa angka bulat')
    .min(1, 'Harga produk harus bernilai minimal 1')
    .when('variants.types', (types, schema) =>
      types.length > 0 ? schema : schema.required('Mohon isi harga produk')
    ),
  stock: Yup.number()
    .typeError('Stok produk harus berupa angka')
    .positive('Stok produk harus berupa angka positif')
    .integer('Stok produk harus berupa angka bulat')
    .min(1, 'Stok produk harus bernilai minimal 1')
    .when('variants.types', (types, schema) =>
      types.length > 0 ? schema : schema.required('Mohon isi stok produk')
    ),
  sku: Yup.string().when('variants.types', (types, schema) =>
    types.length > 0 ? schema : schema.required('Mohon isi sku produk')
  ),
});

const SubCategoryAsyncSelectSearch = ({ loadCategories }) => {
  const {
    values: { category, subCategory },
    setFieldValue,
  } = useFormikContext();
  const [subcategories, setSubcategories] = useState([]);

  useEffect(() => {
    if (!category) return;

    (async () =>
      setSubcategories(
        await loadCategories({
          parentId: category.value,
        })
      ))();
  }, [category, loadCategories]);

  return (
    <AAsyncSelectSearch
      loadOptions={(keyword) =>
        loadCategories({
          keyword,
          parentId: category?.value,
        })
      }
      cacheOptions={category?.value}
      defaultOptions={subcategories}
      isDisabled={!category}
      onChange={(newValue) => setFieldValue('subCategory', newValue)}
      value={subCategory}
    />
  );
};

const EditProduct = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [categories, setCategories] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [initialValues, setInitialValues] = useState({
    images: Array(5).fill({ isUploading: false, url: null }),
    name: '',
    category: null,
    subCategory: null,
    description: '',
    price: '',
    minimumOrderQuantity: '',
    stock: '',
    sku: '',
    weight: '',
    length: '',
    width: '',
    height: '',
    variants: {
      types: [],
      combinations: [],
    },
  });

  const loadCategories = async ({ keyword = null, parentId } = {}) => {
    try {
      return (
        await getCategories({ keyword, parentId })
      ).data.data.categories.map((category) => ({
        label: category.name,
        value: category.id,
      }));
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    (async () => {
      try {
        const [
          {
            data: { data: product },
          },
          categories,
        ] = await Promise.all([getProduct(id), loadCategories()]);

        setCategories(categories);

        setInitialValues({
          ...product,
          images: product.productImages
            .map((image) => ({
              isUploading: false,
              url: image.imageUrl,
            }))
            .concat(
              Array(5 - product.productImages.length).fill({
                isUploading: false,
                url: null,
              })
            ),
          category: product.category.parentCategory
            ? {
                label: product.category.parentCategory.name,
                value: product.category.parentCategory.id,
              }
            : {
                label: product.category.name,
                value: product.category.id,
              },
          subCategory: product.category.parentCategory
            ? {
                label: product.category.name,
                value: product.category.id,
              }
            : null,
          price:
            product.productVariantTypes.length === 0
              ? product.productVariants[0].finalPrice
              : '',
          stock:
            product.productVariantTypes.length === 0
              ? product.productVariants[0].stock
              : '',
          sku:
            product.productVariantTypes.length === 0
              ? product.productVariants[0].sku
              : '',
          variants: {
            types: product.productVariantTypes.map((type) => ({
              name: type.name,
              options: type.productVariantOptions.map((option) => ({
                name: option.name,
                image: { url: option.imageUrl, isUploading: false },
              })),
            })),
            combinations:
              product.productVariantTypes.length > 0
                ? product.productVariants.map((combination) => ({
                    price: combination.finalPrice,
                    sku: combination.sku,
                    stock: combination.stock,
                    isActive: combination.isActive,
                  }))
                : [],
          },
        });
        setIsLoading(false);
      } catch (error) {
        console.error(error);
      }
    })();
  }, [id]);

  return (
    <main>
      {isLoading ? (
        <div className="flex justify-center">
          <ALoading />
        </div>
      ) : (
        <Formik
          validationSchema={ProductSchema}
          enableReinitialize
          initialValues={initialValues}
          onSubmit={async ({
            price,
            images,
            category,
            subCategory,
            variants,
            ...data
          }) => {
            const categoryId = subCategory ? subCategory.value : category.value;

            try {
              if (variants.types.length === 0) {
                await updateNoVariantProduct(id, {
                  categoryId,
                  originalPrice: price,
                  finalPrice: price,
                  imageUrls: images
                    .map((image) => image.url)
                    .filter((imageUrl) => imageUrl !== null),
                  ...data,
                });
              } else {
                await updateIncludeVariantProduct(id, {
                  ...data,
                  imageUrls: images
                    .map((image) => image.url)
                    .filter((imageUrl) => imageUrl !== null),
                  categoryId,
                  variants: {
                    ...variants,
                    types: variants.types.map((type) => ({
                      ...type,
                      options: type.options.map((option) => ({
                        ...option,
                        imageUrl: option.image.url,
                      })),
                    })),
                    combinations: variants.combinations.map((combination) => ({
                      ...combination,
                      originalPrice: combination.price,
                      finalPrice: combination.price,
                      price: undefined,
                    })),
                  },
                });
              }
              navigate('/product');
            } catch (error) {
              console.error(error);
            }
          }}
        >
          {({
            values,
            setFieldValue,
            handleChange,
            handleSubmit,
            isSubmitting,
            handleReset,
            errors,
          }) => (
            <Form>
              <section>
                <p className="sm:text-lg">Sunting Produk</p>
              </section>

              <ABox className="mt-5">
                <div>
                  <p>Foto Produk *</p>
                </div>

                <aside className="flex gap-4 mt-3">
                  <div className="flex flex-wrap gap-5 max-w-4xl">
                    {values.images.map((image, idx) =>
                      image.url !== null ? (
                        <div key={idx} className="relative">
                          <img
                            src={image.url}
                            alt="-"
                            className="w-32 h-32 rounded-md border border-primary flex justify-center items-center text-4xl text-primary cursor-pointer"
                          />
                          <div
                            className="absolute top-0 right-0 bg-slate-300 p-1 hover:bg-opacity-50 cursor-pointer"
                            onClick={() =>
                              setFieldValue(`images[${idx}].url`, null)
                            }
                          >
                            <FiTrash2 size={12} />
                          </div>
                        </div>
                      ) : (
                        <label
                          key={idx}
                          className="w-32 h-32 rounded-md border border-primary flex justify-center items-center text-4xl text-primary hover:bg-gray-100 hover:bg-opacity-50 cursor-pointer"
                        >
                          +
                          <input
                            style={{ display: 'none' }}
                            accept="image/png, image/jpg, image/gif, image/jpeg"
                            type="file"
                            onChange={async (e) => {
                              const [file] = e.target.files;
                              setFieldValue(`images[${idx}]`, {
                                isUploading: true,
                                url: URL.createObjectURL(file),
                              });

                              const formData = new FormData();
                              formData.append('image', file);

                              try {
                                const { imageUrl } = (
                                  await storeProductImage(formData)
                                ).data.data;
                                setFieldValue(`images[${idx}]`, {
                                  isUploading: false,
                                  url: imageUrl,
                                });
                              } catch (error) {
                                console.error(error);
                              }
                            }}
                          />
                        </label>
                      )
                    )}
                  </div>

                  <div className="flex-1">
                    <p className="text-sm">Notes</p>

                    <p className="text-xs mt-1">
                      Upload setidaknya 1 foto dengan resolusi gambar minimal
                      ukuran 300x300 dan untuk gambar optimal berukuran lebih
                      dari 1080x1080
                    </p>

                    {typeof getIn(errors, 'images') === 'string' && (
                      <ErrorMessage
                        name="images"
                        component="p"
                        className="text-xs text-red-500 mt-1"
                      />
                    )}
                  </div>
                </aside>
              </ABox>

              <ABox className="mt-5">
                <div>
                  <p>Informasi Produk</p>
                </div>

                <div className="mt-4">
                  <div className="flex gap-3 items-center">
                    <p className="text-sm w-1/5">Nama Produk</p>
                    <div className="flex-1">
                      <ATextField
                        placeholder="Masukkan minimal 40 karakter agar nama produkmu semakin menaikkan minat pembeli"
                        name="name"
                        value={values.name}
                        handleChange={handleChange}
                      />
                      <ErrorMessage
                        name="name"
                        component="p"
                        className="text-xs text-red-500 mt-1"
                      />
                    </div>
                  </div>
                  <div className="flex gap-3 mt-4 items-center">
                    <p className="text-sm w-1/5">Kategori</p>
                    <div className="flex gap-3 w-4/5 ml-1">
                      <div className="w-1/2">
                        <AAsyncSelectSearch
                          loadOptions={(keyword) => loadCategories({ keyword })}
                          onChange={(newValue) =>
                            setFieldValue('category', newValue)
                          }
                          value={values.category}
                          defaultOptions={categories}
                        />
                        <ErrorMessage
                          name="category"
                          component="p"
                          className="text-xs text-red-500 mt-1"
                        />
                      </div>
                      <div className="w-1/2">
                        <SubCategoryAsyncSelectSearch
                          loadCategories={loadCategories}
                        />
                        <ErrorMessage
                          name="subCategory"
                          component="p"
                          className="text-xs text-red-500 mt-1"
                        />
                      </div>
                    </div>
                  </div>

                  <div className="flex gap-3 mt-4 items-center">
                    <p className="text-sm w-1/5">Deskripsi Produk</p>
                    <div className="flex-1 flex gap-3">
                      <ATextField
                        className="flex-1"
                        multiline
                        paddingLeft="0"
                        height="120px"
                        name="description"
                        value={values.description}
                        handleChange={handleChange}
                      />

                      <div className="w-1/5">
                        <p className="text-sm">Notes</p>

                        <p className="text-xs mt-1">
                          Masukkan minimal 100 kata agar pembeli mudah menemukan
                          barangmu. Pastikan deskripsi produk sudah memenuhi isi
                          spesifikasi produk yang anda jual.
                        </p>

                        <ErrorMessage
                          name="description"
                          component="p"
                          className="text-xs text-red-500 mt-1"
                        />
                      </div>
                    </div>
                  </div>

                  {/* <div className="flex gap-3 mt-4 items-center">
            <p className="text-sm w-1/5">Video Produk</p>
            <ATextField className="flex-1" placeholder="Masukkan URL Youtube" />
          </div> */}
                  <div className="flex gap-3 mt-4 items-center">
                    <p className="text-sm w-1/5">Minimum Pemesanan</p>
                    <div className="md:w-1/4 w-4/5">
                      <ATextField
                        inputMode="numeric"
                        placeholder="Cth : 1"
                        name="minimumOrderQuantity"
                        handleChange={handleChange}
                        value={values.minimumOrderQuantity}
                      />
                      <ErrorMessage
                        name="minimumOrderQuantity"
                        component="p"
                        className="text-xs text-red-500 mt-1"
                      />
                    </div>
                  </div>
                </div>
              </ABox>

              <ABox className="mt-5">
                <div className="flex gap-3 items-center">
                  <p className="text-sm w-1/5">Varian Produk</p>
                  <AButton
                    title="Tanpa Varian"
                    className="w-[15%] py-2.5"
                    onClick={() => {
                      setFieldValue('variants.types', []);
                      setFieldValue('variants.combinations', []);
                    }}
                    theme={
                      values.variants.types.length === 0 ? 'primary' : 'white'
                    }
                  />
                  <AButton
                    title="1 Varian"
                    className="w-[15%] py-2.5"
                    onClick={() => {
                      setFieldValue('variants.types', [
                        { name: '', options: [] },
                      ]);
                      setFieldValue('variants.combinations', []);
                    }}
                    theme={
                      values.variants.types.length === 1 ? 'primary' : 'white'
                    }
                  />
                  <AButton
                    title="2 Varian"
                    className="w-[15%] py-2.5"
                    onClick={() => {
                      setFieldValue('variants.types', [
                        { name: '', options: [] },
                        { name: '', options: [] },
                      ]);
                      setFieldValue('variants.combinations', []);
                    }}
                    theme={
                      values.variants.types.length === 2 ? 'primary' : 'white'
                    }
                  />
                </div>

                {values.variants.types.map((type, idx) => (
                  <aside key={idx} className="mt-3">
                    <div>
                      <p className="text-sm">{`Varian ${idx + 1}`}</p>
                    </div>
                    <div className="flex gap-3 mt-2">
                      <div className="w-1/4">
                        <ATextField
                          placeholder="Masukkan Nama Varian"
                          name={`variants.types[${idx}].name`}
                          handleChange={handleChange}
                          value={type.name}
                        />
                        {typeof getIn(errors, `variants.types[${idx}].name`) ===
                          'string' && (
                          <ErrorMessage
                            name={`variants.types[${idx}].name`}
                            component="p"
                            className="text-xs text-red-500 mt-1"
                          />
                        )}
                      </div>
                      <div className="w-3/4 flex flex-col">
                        <CreatableSelect
                          isMulti
                          className="w-full flex-1 text-sm font-medium"
                          onChange={(val) => {
                            const combinations = Array.from(
                              {
                                length: values.variants.types.reduce(
                                  (length, type, index) =>
                                    length *
                                    (index === idx
                                      ? val.length
                                      : type.options.length),
                                  1
                                ),
                              },
                              () => ({
                                price: '',
                                sku: '',
                                stock: '',
                                isActive: true,
                              })
                            );

                            setFieldValue(
                              `variants.combinations`,
                              combinations
                            );

                            setFieldValue(
                              `variants.types[${idx}].options`,
                              val.map(({ value }) => ({
                                name: value,
                                image: {
                                  isUploading: false,
                                  url: null,
                                },
                              }))
                            );
                          }}
                          value={type.options.map((option) => ({
                            label: option.name,
                            value: option.name,
                          }))}
                        />
                        {typeof getIn(
                          errors,
                          `variants.types[${idx}].options`
                        ) === 'string' && (
                          <ErrorMessage
                            name={`variants.types[${idx}].options`}
                            component="p"
                            className="text-xs text-red-500 mt-1"
                          />
                        )}
                      </div>
                    </div>
                  </aside>
                ))}

                {values.variants.types.length > 0 && (
                  <div className="flex flex-wrap gap-5 mt-5">
                    {values.variants.types.map((type, typeIdx) =>
                      type.options.map(({ image, name: optionName }, idx) => (
                        <div className="flex flex-col items-center" key={idx}>
                          {image.url !== null ? (
                            <div key={idx} className="relative">
                              <img
                                src={image.url}
                                alt="-"
                                className="w-32 h-32 rounded-md border border-primary flex justify-center items-center text-4xl text-primary cursor-pointer"
                              />
                              <div
                                className="absolute top-0 right-0 bg-slate-300 p-1 hover:bg-opacity-50 cursor-pointer"
                                onClick={() =>
                                  setFieldValue(
                                    `variants.types[${typeIdx}].options[${idx}].image`,
                                    {
                                      isUploading: false,
                                      url: null,
                                    }
                                  )
                                }
                              >
                                <FiTrash2 size={12} />
                              </div>
                            </div>
                          ) : (
                            <label
                              key={idx}
                              className="w-32 h-32 rounded-md border border-primary flex justify-center items-center text-4xl text-primary hover:bg-gray-100 hover:bg-opacity-50 cursor-pointer"
                            >
                              +
                              <input
                                style={{ display: 'none' }}
                                accept="image/png, image/jpg, image/gif, image/jpeg"
                                type="file"
                                onChange={async (e) => {
                                  const [file] = e.target.files;
                                  setFieldValue(
                                    `variants.types[${typeIdx}].options[${idx}].image`,
                                    {
                                      isUploading: true,
                                      url: URL.createObjectURL(file),
                                    }
                                  );

                                  const formData = new FormData();
                                  formData.append('image', file);

                                  try {
                                    const { imageUrl } = (
                                      await storeProductVariantOptionImage(
                                        formData
                                      )
                                    ).data.data;
                                    setFieldValue(
                                      `variants.types[${typeIdx}].options[${idx}].image`,
                                      {
                                        isUploading: false,
                                        url: imageUrl,
                                      }
                                    );
                                  } catch (error) {
                                    console.error(error);
                                  }
                                }}
                              />
                            </label>
                          )}
                          <p className="text-sm">
                            {type.name} - {optionName}
                          </p>
                          {typeof getIn(
                            errors,
                            `variants.types[${typeIdx}].options[${idx}].image.url`
                          ) === 'string' && (
                            <ErrorMessage
                              name={`variants.types[${typeIdx}].options[${idx}].image.url`}
                              component="p"
                              className="text-xs text-red-500 mt-1"
                            />
                          )}
                        </div>
                      ))
                    )}
                  </div>
                )}

                {values.variants.combinations.length > 0 && (
                  <table className="mt-5 w-full">
                    <thead>
                      <tr className="border-b text-sm">
                        {values.variants.types.map(({ name }, idx) => (
                          <td key={idx} className="px-4 py-3 text-start">
                            {name}
                          </td>
                        ))}
                        <td className="px-4">Harga</td>
                        <td className="px-4">Stok</td>
                        <td className="px-4">SKU</td>
                        <td className="px-4">Status</td>
                      </tr>
                    </thead>

                    <tbody>
                      {values.variants.combinations.map((combination, idx) => (
                        <tr key={idx} className="border-b text-sm">
                          {values.variants.types.map((type, typeIndex) => (
                            <td
                              className="px-4 py-6 text-start"
                              key={typeIndex}
                            >
                              {
                                type.options[
                                  values.variants.types.length === typeIndex + 1
                                    ? idx % type.options.length
                                    : Math.floor(
                                        idx /
                                          values.variants.types
                                            .slice(
                                              typeIndex + 1,
                                              values.variants.types.length
                                            )
                                            .reduce(
                                              (length, currentType) =>
                                                length *
                                                currentType.options.length,
                                              1
                                            )
                                      )
                                ].name
                              }
                            </td>
                          ))}
                          <td className="px-4">
                            <ATextField
                              placeholder="Harga"
                              value={combination.price}
                              handleChange={handleChange}
                              name={`variants.combinations[${idx}].price`}
                              InputProps={{
                                startAdornment: (
                                  <InputAdornment position="start">
                                    <p className="text-xs">Rp</p>
                                  </InputAdornment>
                                ),
                              }}
                            />
                            {typeof getIn(
                              errors,
                              `variants.combinations[${idx}].price`
                            ) === 'string' && (
                              <ErrorMessage
                                name={`variants.combinations[${idx}].price`}
                                component="p"
                                className="text-xs text-red-500 mt-1"
                              />
                            )}
                          </td>
                          <td className="px-4">
                            <ATextField
                              inputMode="numeric"
                              placeholder="Stok"
                              value={combination.stock}
                              handleChange={handleChange}
                              name={`variants.combinations[${idx}].stock`}
                            />
                            {typeof getIn(
                              errors,
                              `variants.combinations[${idx}].stock`
                            ) === 'string' && (
                              <ErrorMessage
                                name={`variants.combinations[${idx}].stock`}
                                component="p"
                                className="text-xs text-red-500 mt-1"
                              />
                            )}
                          </td>
                          <td className="px-4">
                            <ATextField
                              placeholder="SKU"
                              value={combination.sku}
                              handleChange={handleChange}
                              name={`variants.combinations[${idx}].sku`}
                            />
                            {typeof getIn(
                              errors,
                              `variants.combinations[${idx}].sku`
                            ) === 'string' && (
                              <ErrorMessage
                                name={`variants.combinations[${idx}].sku`}
                                component="p"
                                className="text-xs text-red-500 mt-1"
                              />
                            )}
                          </td>
                          <td className="px-4">
                            <ASwitchButton
                              isChecked={combination.isActive}
                              onChange={(event) =>
                                setFieldValue(
                                  `variants.combinations[${idx}].isActive`,
                                  event.target.checked
                                )
                              }
                            />
                            {typeof getIn(
                              errors,
                              `variants.combinations[${idx}].isActive`
                            ) === 'string' && (
                              <ErrorMessage
                                name={`variants.combinations[${idx}].isActive`}
                                component="p"
                                className="text-xs text-red-500 mt-1"
                              />
                            )}
                          </td>

                          {/* <td className="px-4 text-sm">
                          {combination.isActive ? (
                            <AButton
                              title="Utama"
                              className="py-1"
                              theme="primary"
                            />
                          ) : (
                            <div>
                              <AButton
                                title="Utama"
                                className="py-1 bg-opacity-40 opacity-0 hover:opacity-100"
                                theme="primary"
                                // onClick={() => {
                                //   setMainVariant(idx);
                                //   setShowMainVariant(idx);
                                // }}
                              />
                            </div>
                          )}
                        </td> */}
                        </tr>
                      ))}
                    </tbody>
                  </table>
                )}
              </ABox>

              {values.variants.types.length === 0 && (
                <ABox className="mt-5">
                  <div>
                    <p>Harga & Pengaturan Produk</p>
                  </div>
                  <div className="mt-4">
                    <div className="flex gap-3 items-center">
                      <p className="text-sm w-1/5">Harga</p>
                      <div className="md:w-1/4 w-4/5">
                        <ATextField
                          inputMode="numeric"
                          placeholder="Cth : Rp24.000"
                          name="price"
                          handleChange={handleChange}
                          value={values.price}
                        />
                        <ErrorMessage
                          name="price"
                          component="p"
                          className="text-xs text-red-500 mt-1"
                        />
                      </div>
                    </div>
                    <div className="flex gap-3 mt-4 items-center">
                      <p className="text-sm w-1/5">Stok Produk</p>
                      <div className="md:w-1/4 w-4/5">
                        <ATextField
                          inputMode="numeric"
                          placeholder="Cth : 1000"
                          value={values.stock}
                          name="stock"
                          handleChange={handleChange}
                        />
                        <ErrorMessage
                          name="stock"
                          component="p"
                          className="text-xs text-red-500 mt-1"
                        />
                      </div>
                    </div>
                    <div className="flex gap-3 mt-4 items-center">
                      <p className="text-sm w-1/5">SKU</p>
                      <div className="md:w-1/4 w-4/5">
                        <ATextField
                          type="text"
                          placeholder="Masukkan SKU"
                          value={values.sku}
                          name="sku"
                          handleChange={handleChange}
                        />
                        <ErrorMessage
                          name="sku"
                          component="p"
                          className="text-xs text-red-500 mt-1"
                        />
                      </div>
                    </div>
                  </div>
                </ABox>
              )}

              <ABox className="mt-5">
                <div>
                  <p>Pengiriman</p>
                </div>
                <div className="mt-4">
                  <div className="flex gap-3 items-center">
                    <p className="text-sm w-1/5">Berat</p>
                    <div className="md:w-2/5 w-4/5">
                      <ATextField
                        inputMode="decimal"
                        placeholder="Mohon masukkan berat dalam satuan gram"
                        name="weight"
                        value={values.weight}
                        handleChange={handleChange}
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              <p className="text-xs">gr</p>
                            </InputAdornment>
                          ),
                        }}
                      />
                      <ErrorMessage
                        name="weight"
                        component="p"
                        className="text-xs text-red-500 mt-1"
                      />
                    </div>
                  </div>
                  <div className="flex gap-3 mt-4 items-center">
                    <p className="text-sm w-1/5">Ukuran Produk</p>
                    <div className="md:w-2/5 flex gap-3">
                      <div>
                        <ATextField
                          placeholder="Panjang"
                          inputMode="decimal"
                          name="length"
                          value={values.length}
                          handleChange={handleChange}
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">
                                <p className="text-xs">cm</p>
                              </InputAdornment>
                            ),
                          }}
                        />
                        <ErrorMessage
                          name="length"
                          component="p"
                          className="text-xs text-red-500 mt-1"
                        />
                      </div>
                      <div>
                        <ATextField
                          placeholder="Lebar"
                          inputMode="decimal"
                          name="width"
                          value={values.width}
                          handleChange={handleChange}
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">
                                <p className="text-xs">cm</p>
                              </InputAdornment>
                            ),
                          }}
                        />
                        <ErrorMessage
                          name="width"
                          component="p"
                          className="text-xs text-red-500 mt-1"
                        />
                      </div>
                      <div>
                        <ATextField
                          placeholder="Tinggi"
                          inputMode="decimal"
                          name="height"
                          handleChange={handleChange}
                          value={values.height}
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">
                                <p className="text-xs">cm</p>
                              </InputAdornment>
                            ),
                          }}
                        />
                        <ErrorMessage
                          name="height"
                          component="p"
                          className="text-xs text-red-500 mt-1"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </ABox>

              <section className="mt-5">
                <div className="flex gap-3 items-center justify-end">
                  <AButton
                    title="Atur Ulang"
                    className="w-[15%] py-2.5"
                    theme="softGray"
                    onClick={handleReset}
                  />
                  <AButton
                    title="Sunting Produk"
                    className="w-[15%] py-2.5"
                    onClick={handleSubmit}
                    type="submit"
                    disabled={
                      isSubmitting ||
                      values.images.some((image) => image.isUploading) ||
                      values.variants.types.some((type) =>
                        type.options.some((option) => option.image.isUploading)
                      )
                    }
                  />
                </div>
              </section>
            </Form>
          )}
        </Formik>
      )}
    </main>
  );
};

export default EditProduct;
