import React from "react";
import MNavbar from "../molecules/MNavbar";
import MSidebar from "../molecules/MSidebar";
import Wrapper from "./Wrapper";

const OLayout = ({ children }) => {
  return (
    <main className="h-screen max-h-screen overflow-auto">
      <MNavbar />
      <section className="flex w-full h-full">
        <MSidebar />
        <div id="scrollEl" className="flex-1 h-screen overflow-y-scroll bg-secondary bg-opacity-20 pt-[110px] pb-7 px-7">
          <Wrapper>{children}</Wrapper>
        </div>
      </section>
    </main>
  );
};

export default OLayout;
