import { LoadingButton } from '@mui/lab';
import { useFormik } from 'formik';
import OTPInput, { ResendOTP } from 'otp-input-react';
import React, { useState } from 'react';
import * as Yup from 'yup';
import ADialogBox from '../../atoms/ADialogBox';

const renderButton = (buttonProps) => (
  <div className="flex gap-1">
    <p> Tidak Menerima Kode?</p>
    <button
      className="text-primary"
      disabled={buttonProps.disabled}
      onClick={buttonProps.onClick}
      type="button"
    >
      <h1
        className={`flex text-xs font-normal ${
          buttonProps.remainingTime !== 0
            ? 'cursor-not-allowed text-black'
            : 'cursor-pointer'
        }`}
      >
        Kirim Ulang
        {buttonProps.remainingTime !== 0 && ` (${buttonProps.remainingTime}s)`}
      </h1>
    </button>
  </div>
);

function OVerifOtpDialog({ isOpen, setIsOpen, onSubmit, onResendClick }) {
  const [isLoading, setIsLoading] = useState(false);
  const { setValues, handleSubmit, values, errors, touched, resetForm } =
    useFormik({
      initialValues: {
        otp: '',
      },
      validationSchema: Yup.object({
        otp: Yup.string()
          .required('Otp tidak boleh kosong.')
          .min(6, 'Minimum mengandung 6 karakter.'),
      }),
      onSubmit: async (values) => {
        setIsLoading(true);
        try {
          await onSubmit(values);
        } finally {
          setIsLoading(false);
        }
      },
    });

  return (
    <ADialogBox
      content={{
        title: 'Verifikasi',
        body: (
          <form onSubmit={handleSubmit}>
            <p className="text-sm font-bold">Masukkan Kode Verifikasi</p>
            <p className="text-xs mt-0.5">
              Kode verifikasi telah dikirimkan ke nomor anda
            </p>
            <div className="my-2">
              <OTPInput
                value={values.otp}
                onChange={(e) => setValues({ ...values, otp: e })}
                autoFocus
                OTPLength={6}
                otpType="number"
                disabled={false}
                secure={false}
                className="grid grid-cols-6 gap-4"
                inputClassName="border-b border-softBlack text-2xl focus:outline-none focus:ring-0"
                inputStyles={{ height: '50px', width: '100%', marginRight: 0 }}
              />
              {touched.otp && errors.otp && (
                <p className="text-xs mt-2 text-error">{errors.otp}</p>
              )}
              <div className="text-xs mt-2">
                <ResendOTP
                  maxTime={60}
                  onResendClick={onResendClick}
                  renderButton={renderButton}
                  renderTime={() => <></>}
                />
              </div>
            </div>
            <div className="mt-3 mb-1">
              <LoadingButton
                type="submit"
                variant="contained"
                size="medium"
                style={{ width: '100%' }}
                loading={isLoading}
              >
                Kirim
              </LoadingButton>
            </div>
          </form>
        ),
      }}
      isOpen={isOpen}
      setIsOpen={(value) => {
        resetForm();
        setIsOpen(value);
      }}
    />
  );
}

export default OVerifOtpDialog;
