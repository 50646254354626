import { Avatar, Menu, MenuItem } from '@mui/material';
import React, { useContext, useState } from 'react';
import { FiLogOut } from 'react-icons/fi';
import { MdOutlineHistory } from 'react-icons/md';
import { useNavigate } from 'react-router-dom';
import AuthContext from '../../contexts/AuthContext';

const MNavbar = () => {
  const navigate = useNavigate();
  // const { GlobalDispatch, GlobalState } = useContext(GlobalContext);
  // const { openSidebar } = GlobalState;

  // const handleOpenSidebar = () => {
  //   GlobalDispatch({
  //     type: ActionType.SetOpenSidebar,
  //     payload: !openSidebar,
  //   });
  // };

  const { authData, setAuthData } = useContext(AuthContext);

  const [anchorEl, setAnchorEl] = useState(null);

  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const navigateTo = (link) => {
    navigate(link);
    handleClose();
  };

  return (
    <nav className="fixed h-20 z-10 flex justify-end px-7 w-full bg-white shadow-md">
      <div className="flex gap-8 items-center justify-end w-[20%]">
        <div className="flex justify-evenly gap-4 sm:gap-6 items-center">
          <div className="sm:flex mt-1 hidden cursor-pointer">
            {/* <Badge badgeContent={1000} color="primary" max={99}>
              <IoIosNotificationsOutline size={30} className="fill-primary" />
            </Badge> */}
          </div>
          <div
            className="cursor-pointer w-8 sm:hidden"
            // onClick={() => navigateTo('/account')}
          >
            <Avatar sx={{ width: '30px', height: '30px' }} />
          </div>
        </div>
        <div className="sm:flex flex-1 hidden p-2 bg-white rounded-full h-11 shadow-sm">
          <div
            className="flex gap-2 items-center w-full cursor-pointer"
            onClick={handleClick}
          >
            <Avatar sx={{ width: '27px', height: '27px' }} />
            <div className="w-4/5">
              <p className="truncate text-sm">{authData.fullName}</p>
            </div>
          </div>
          <Menu
            id="basic-menu"
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            MenuListProps={{
              'aria-labelledby': 'basic-button',
            }}
            PaperProps={{
              elevation: 0,
              sx: {
                overflow: 'visible',
                filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                mt: 1.7,
                ml: 1,
                width: '14%',
                '&:before': {
                  content: '""',
                  display: 'block',
                  position: 'absolute',
                  top: 0,
                  left: 12,
                  width: 10,
                  height: 10,
                  bgcolor: 'background.paper',
                  transform: 'translateY(-50%) rotate(45deg)',
                  zIndex: 0,
                },
              },
            }}
            transformOrigin={{ horizontal: 'right', vertical: 'top' }}
            anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
          >
            {/* <MenuItem
              className="flex gap-2"
              onClick={() => navigateTo('/settings/profile')}
            >
              <FaRegUser /> Akun
            </MenuItem> */}
            <MenuItem
              className="flex gap-2"
              onClick={() => navigateTo('/order')}
            >
              <MdOutlineHistory /> Transaksi
            </MenuItem>
            <MenuItem
              className="flex gap-2"
              onClick={() => {
                setAuthData(null);
                localStorage.clear();
              }}
            >
              <FiLogOut /> Keluar
            </MenuItem>
          </Menu>
        </div>
      </div>
    </nav>
  );
};

export default MNavbar;
