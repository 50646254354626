import React from 'react';
import { Route, Routes } from 'react-router-dom';
import AddProduct from '../../pages/product/AddProduct';
import EditProduct from '../../pages/product/EditProduct';

const Product = React.lazy(() => import('../../pages/product/Product'));

const ProductRoute = () => {
  return (
    <Routes>
      <Route path="/" element={<Product />} />
      <Route path="/add-product" element={<AddProduct />} />
      <Route path="/edit-product/:id" element={<EditProduct />} />
    </Routes>
  );
};

export default ProductRoute;
