import { createContext, useEffect, useState } from 'react';
import { getMe } from '../api/user';

const AuthContext = createContext({});

export function AuthProvider({ children }) {
  const [authData, setAuthData] = useState();

  useEffect(() => {
    if (!localStorage.getItem('accessToken')) {
      return;
    }

    (async () => {
      try {
        const data = (await getMe()).data.data;
        setAuthData(data);
      } catch (error) {
        console.error(error);
      }
    })();
  }, []);

  return (
    <AuthContext.Provider value={{ authData, setAuthData }}>
      {children}
    </AuthContext.Provider>
  );
}

export default AuthContext;
