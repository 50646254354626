import ReactLoading from "react-loading";

export const ALoading = ({
  color = "#2191FB",
  width = 100,
  height = 100,
  type = "bubbles",
}) => {
  return <ReactLoading type={type} color={color} height={height} width={width} />;
};
