import {
    createTheme,
    experimental_sx as sx
} from "@mui/material";

export const theme = createTheme({
    palette: {
        primary: {
            main: "#2191FB",
        },
        secondary: {
            main: "#E1F5FE",
        },
        neutral: {
            main: "#9E9D9D",
            contrastText: "#FFF"
        }
    },
    components: {
        MuiOutlinedInput: {
            styleOverrides: {
                input: {
                    padding: "10px",
                    fontSize: ".8rem"
                },
            }
        },
        MuiInputLabel: {
            styleOverrides: {
                root: {
                    fontSize: "0.8rem", paddingTop: ".2rem"
                },
                shrink: { fontSize: "1rem" },
            }
        },
        MuiMenuItem: {
            styleOverrides: {
                root: {
                    color: "#2191FB",
                },
            }
        },
        MuiButton: {
            styleOverrides: {
                root: {
                    textTransform: "none",
                },
            }
        },
        MuiSelect: {
            styleOverrides: {
                root: {
                    color: '#2191FB',
                },
                icon: sx({
                    fill: "#2191FB"
                }),
                select: sx({
                    '&:before': {
                        borderColor: "#2191FB"
                    },
                    '&:after': {
                        borderColor: "#2191FB"
                    },
                    '&:hover:not(.Mui-disabled):before': {
                        borderColor: '#2191FB',
                    },
                })
            }
        }
    }
});