import React from "react";
import { Route, Routes } from "react-router-dom";

const Order = React.lazy(() => import("../../pages/order/Order"));
const OrderDetail = React.lazy(() => import("../../pages/order/OrderDetail"));

const OrderRoute = () => {
  return (
    <Routes>
      <Route path="/" element={<Order />} />
      <Route path="/:id" element={<OrderDetail />} />
    </Routes>
  );
};

export default OrderRoute;
