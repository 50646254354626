import React from 'react';

const primary =
  'px-4 py-1.5 bg-primary text-white hover:bg-opacity-70 shadow-md';
const secondary =
  'px-4 py-1.5 bg-transparent border border-primary text-primary hover:bg-primary hover:bg-opacity-10';
const link = 'bg-transparent text-primary hover:text-opacity-70 px-0 py-1';
const softGray =
  'px-4 py-1.5 bg-softGray text-white hover:bg-opacity-70 shadow-md';
const white =
  'px-4 py-1.5 bg-white text-extraSoftBlack hover:bg-opacity-70 hover:bg-gray-50 shadow-sm';
const disabled = 'opacity-50 cursor-not-allowed';

const buttonStyle = {
  primary,
  secondary,
  link,
  softGray,
  white,
  disabled,
};

const AButton = ({
  onClick,
  title,
  theme = 'primary',
  size = 'sm',
  width,
  icon,
  className,
  type = 'button',
  disabled,
}) => {
  return (
    <button
      className={`flex justify-center items-center rounded-md my-2 text-${size} ${
        buttonStyle[theme]
      } w-${width} ${disabled ? buttonStyle.disabled : ''} ${className || ''}`}
      onClick={onClick}
      type={type}
      disabled={disabled}
    >
      {icon}
      {title}
    </button>
  );
};
export default AButton;
