import { subsPhoneNumber } from '../utils/common';
import { adminAxios } from './axios';

export const getOtp = ({ phoneNumber, countryCode }) =>
  adminAxios.post('admins/get-otp', {
    phoneNumber: subsPhoneNumber(phoneNumber),
    countryCode,
  });

export const verifyOtp = ({ phoneNumber, countryCode, otp }) =>
  adminAxios.post('customers/verify-otp', {
    phoneNumber: subsPhoneNumber(phoneNumber),
    countryCode,
    otp,
  });
