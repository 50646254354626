import React from "react";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import { GrClose } from "react-icons/gr";

const ADialogBox = ({ isOpen, setIsOpen, content }) => {
  return (
    <Dialog
      maxWidth="xs"
      fullWidth
      onClose={() => setIsOpen(false)}
      open={isOpen}
    >
      <DialogTitle>
        <div className="flex justify-between items-center">
          <p className="font-bold text-lg text-primary">{content.title}</p>
          <GrClose
            cursor="pointer"
            size={20}
            onClick={() => setIsOpen(false)}
          />
        </div>
      </DialogTitle>
      <DialogContent dividers>{content.body}</DialogContent>
      {content.footer}
    </Dialog>
  );
};

export default ADialogBox;
