import React, { Suspense, useContext } from 'react';
import { Navigate, Outlet, Route, Routes, useLocation } from 'react-router-dom';

import { ALoading } from '../components/atoms/ALoading';
import OLayout from '../components/organisms/OLayout';
import AuthContext from '../contexts/AuthContext';
import Login from '../pages/auth/Login';
import NoMatch from '../pages/noMatch/404';
import DashboardRoute from './dashboard';
import OrderRoute from './order';
import ProductRoute from './product';
import SettingsRoute from './settings';

function RequireAuth({ children }) {
  const { authData } = useContext(AuthContext);
  const location = useLocation();

  if (!authData && !localStorage.getItem('accessToken')) {
    return <Navigate to="/login" state={{ from: location }} replace />;
  }

  if (!authData)
    return (
      <div className="w-screen h-screen flex items-center justify-center">
        <ALoading />
      </div>
    );

  return children;
}

const MainRoute = () => {
  return (
    <Routes>
      <Route path="/login" element={<Login />} />
      <Route
        path="/"
        element={
          <RequireAuth>
            <OLayout>
              <Suspense
                fallback={
                  <div className="flex justify-center pt-28">
                    <ALoading />
                  </div>
                }
              >
                <Outlet />
              </Suspense>
            </OLayout>
          </RequireAuth>
        }
      >
        <Route path="/dashboard/*" element={<DashboardRoute />} />
        <Route path="/product/*" element={<ProductRoute />} />
        <Route path="/order/*" element={<OrderRoute />} />
        <Route path="/settings/*" element={<SettingsRoute />} />

        <Route path="/" element={<Navigate replace to="/dashboard" />} />
      </Route>
      <Route path="*" element={<NoMatch />} />
    </Routes>
  );
};

export default MainRoute;
