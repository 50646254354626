import React from "react";
import { Link, useLocation } from "react-router-dom";
import { Menu, MenuItem, SubMenu } from "react-pro-sidebar";
import { isMobile } from "react-device-detect";
import { isEqual } from "lodash";

const AMenu = ({ item, handleCollapseSidebar, handleLogout }) => {
  const { pathname } = useLocation();

  return (
    <Menu
      iconShape="square"
      key={item.path}
      className={"w-full " + (item.hideOnDesktop && "md:hidden")}
    >
      {item?.children ? (
        <SubMenu
          className="-mt-10 mb-2.5 pl-0.5 font-sans font-medium my-5"
          title={item.title}
          icon={<item.icon />}
        >
          {item.children.map((el, idx) => (
            <MenuItem
              key={idx}
              className={
                "pl-4 font-sans font-medium mt-2 h-7 flex items-center " +
                (isEqual(pathname, el.path)
                  ? "text-primary border-r-[2.5px] border-primary duration-300"
                  : "hover:text-primary") +
                (idx === 0 && " -mt-4")
              }
              icon={<el.icon />}
              onClick={isMobile ? handleCollapseSidebar : () => {}}
            >
              <Link to={el.path} />
              {el.title}
            </MenuItem>
          ))}
        </SubMenu>
      ) : (
        <MenuItem
          className={
            "-mt-8 pl-0.5 font-sans font-medium h-7 my-5 flex items-center " +
            (pathname.indexOf(item.path) !== -1
              ? "text-primary border-r-[2.5px] border-primary duration-300"
              : "hover:text-primary")
          }
          icon={<item.icon />}
          onClick={isMobile ? handleCollapseSidebar : () => {}}
        >
          <Link
            onClick={isEqual(item.path, "login") ? handleLogout : () => {}}
            to={item.path}
          />
          {item.title}
        </MenuItem>
      )}
    </Menu>
  );
};

export default AMenu;
