import axios from 'axios';
import { camelizeKeys, decamelizeKeys } from 'humps';
import qs from 'qs';

axios.defaults.headers.common['Accept'] = 'application/json';
axios.defaults.withCredentials = true;

export const adminAxios = axios.create({
  baseURL: process.env.REACT_APP_API_BASE_URL,
});

const controller = new AbortController();

adminAxios.interceptors.request.use(
  (config) => {
    const accessToken = localStorage.getItem('accessToken');
    if (accessToken) {
      config.headers.Authorization = `Bearer ${accessToken}`;
    }

    const outletId = localStorage.getItem('outletId');
    if (outletId) {
      config.headers.outletid = outletId;
    }

    config.headers.timezone_id = 'Asia/Jakarta';

    config.signal = controller.signal;

    if (config.headers['Content-Type'] === 'multipart/form-data') return config;

    config.paramsSerializer = (params) =>
      qs.stringify(params, { skipNulls: true, arrayFormat: 'comma' });

    if (config.data) config.data = decamelizeKeys(config.data);
    if (config.params) config.params = decamelizeKeys(config.params);

    return config;
  },
  (error) => Promise.reject(error)
);

adminAxios.interceptors.response.use(
  (response) => camelizeKeys(response),
  (error) => {
    if (error.response?.status === 401) {
      controller.abort();
      localStorage.clear();
      window.location.reload();
    }

    return Promise.reject(error);
  }
);
